<template>
  <div class="ohgroup-create">
    <ui-wizard
      :title="$t('ohgroup.create.title')"
      :breadcrumbs="breadcrumbs"
      :is-saving="isSaving"
      @back="onBack"
      @on-complete="onComplete"
      @update:startIndex="updateIndex"
      :is-valid="!$v.$error"
    >
      <ui-wizard-step :title="$t('ohgroup.create.step_one.title')" :before-change="checkRule">
        <ui-dropdown
          class="ohgroup-create__input"
          id="ohgroup-rule"
          v-model="rule"
          :options="currentClient.locationOpeningHoursRules"
          :placeholder="$t('ohgroup.create.step_one.placeholder')"
          :dropdown-label="$t('ohgroup.create.step_one.label')"
          label="name"
          :show-label="true"
          :error="$v.rule.$error"
          :is-required="true"
          @input="ruleChanged"
        >
          <template v-if="$v.rule.$error && !$v.rule.required">
            {{ $t('errors.required') }}
          </template>
        </ui-dropdown>
      </ui-wizard-step>
      <ui-wizard-step :title="$t('ohgroup.create.step_two.title')" :before-change="checkGroup">
        <div class="ohgroup-create__group">
          <div class="ohgroup-create__group__input">
            <ui-input
              class="ohgroup-create__group__input__name"
              v-model.trim="group.name"
              :label="$t('ohgroup.create.step_two.label')"
              id="ohgroup-group-name"
              :error="$v.group.name.$error"
              :is-required="true"
            >
              <template v-if="$v.group.name.$error && !$v.group.name.required">
                {{ $t('errors.required') }}
              </template>
            </ui-input>

            <ui-tag
              class="ohgroup-create__group__input__countries"
              v-model="group.countries"
              id="ohgroup-countries"
              :placeholder="$t('ohgroup.create.step_two.tag.placeholder')"
              :tag-label="$t('ohgroup.create.step_two.tag.label')"
              :show-label="true"
              label="name"
              track-by="name"
              :options="ohGroupCountriesParsed"
              :error="$v.group.countries.$error"
              :is-required="true"
            >
              <template v-slot:helper v-if="$v.group.countries.$error && !$v.group.countries.required">
                {{ $t('errors.required') }}
              </template>
            </ui-tag>
          </div>

          <oh-group-hours :choosen-week-days="group.weekdays" ref="groupHours" />
        </div>
      </ui-wizard-step>

      <ui-wizard-step :title="$t('ohgroup.create.step_three.title')">
        <div class="ohgroup-create__subgroup" v-for="(sub, idx) in subgroups" :key="idx">
          <div class="ohgroup-create__subgroup__input">
            <ui-input
              class="ohgroup-create__subgroup__input__name"
              v-model.trim="sub.name"
              :label="
                sub.default === '1'
                  ? `${$t('ohgroup.create.step_three.label')} (${$t('common.label.default')})`
                  : $t('ohgroup.create.step_three.label')
              "
              :id="`ohgroup-subgroup-name-${idx}`"
              :error="$v.subgroups.$each[idx].name.$error"
              :is-required="true"
            >
              <template v-if="$v.subgroups.$each[idx].name.$error && !$v.subgroups.$each[idx].name.required">
                {{ $t('errors.required') }}
              </template>
            </ui-input>
          </div>

          <oh-group-hours :choosen-week-days="sub.weekdays" :index="idx" :ref="`subgroupHours${idx}`" />
        </div>

        <screen-action
          class="ohgroup-create__addbutton"
          :label="$t('ohgroup.create.step_three.add')"
          icon="add"
          :is-loading="isUpdating"
          @click="addSubgroup()"
        />
      </ui-wizard-step>
    </ui-wizard>
  </div>
</template>

<script>
import { notif } from '@/mixins/notification.mixin'
import { mapActions, mapState } from 'vuex'
import ScreenAction from '@/components/Screen/Action.vue'
import UiWizard from '@/components/UI/Wizard/Wizard.vue'
import UiWizardStep from '@/components/UI/Wizard/Step.vue'
import UiInput from '@/components/UI/Input.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiTag from '@/components/UI/Tag.vue'
import OhGroupHours from '@/components/OhGroup/Hours.vue'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'OhGroupCreate',
  components: {
    ScreenAction,
    UiWizard,
    UiWizardStep,
    UiInput,
    UiDropdown,
    UiTag,
    OhGroupHours,
  },
  mixins: [notif],
  data() {
    return {
      isEditing: false,
      isSaving: false,
      rule: '',
      wizardIndex: 0,
      editingModal: '',
      ohGroupCountriesParsed: [],
      group: {
        name: '',
        countries: [],
        hours: [],
        weekdays: [],
      },
      subgroups: [{ name: '', hours: [], weekdays: [], default: '1' }],
      subgroupWeekday: [],
      daysArray: [
        {
          key: 'monday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'tuesday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'wednesday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'thursday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'friday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'saturday',
          open: false,
          h24: false,
          slots: [],
        },
        {
          key: 'sunday',
          open: false,
          h24: false,
          slots: [],
        },
      ],
    }
  },
  async mounted() {
    if (this.currentClient.locationOpeningHoursRules.length === 1) {
      this.rule = this.currentClient.locationOpeningHoursRules[0]
    }

    await this.getOhGroupCountries()
    this.ohGroupCountriesParsed = this.countries.filter(country =>
      this.ohGroupCountries.some(ohCountry => ohCountry.countryCode === country.countryCode)
    )
  },
  watch: {
    rule: {
      handler() {
        this.group.weekdays = this.daysArray.filter(day => this.rule.weekdays.includes(day.key))

        this.subgroupWeekday = this.daysArray.filter(day => !this.rule.weekdays.includes(day.key))
        this.subgroups[0].weekdays = this.subgroupWeekday
      },
    },
  },
  computed: {
    ...mapState({
      currentClient: state => state.client.currentClient,
      ohGroupCountries: state => state.ohgroup.ohGroupCountries,
      countries: state => state.backoffice.countries,
    }),
    breadcrumbs() {
      return [
        {
          label: this.$t('ohgroup.list.breadcrumb'),
          route: {
            name: 'OhGroup',
          },
        },
        {
          label: this.$t('ohgroup.create.breadcrumb'),
          route: {
            name: 'OhGroupCreate',
          },
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getOhGroupCountries: 'ohgroup/getOhGroupCountries',
      createOhGroup: 'ohgroup/createOhGroup',
      createOhSubgroup: 'ohgroup/createOhSubgroup',
    }),
    ruleChanged(selectedRule) {
      this.rule = selectedRule
    },
    onBack() {
      this.$router.push({ name: 'OhGroup' })
    },
    async onComplete() {
      this.$v.$touch()
      if (!this.$v.$invalid && this.checkSubgroup()) {
        this.isSaving = true

        try {
          await this.createOhGroup(this.group)
        } catch (err) {
          this.isSaving = false
          this.notificationError()
          return
        }

        try {
          const promises = this.subgroups.map(subgroup => {
            return this.createOhSubgroup(subgroup)
          })
          await Promise.all(promises)
          this.notificationSuccess('ohgroup', this.group.name, 'create')
          this.$router.push({ name: 'OhGroup' })
          this.isSaving = false
        } catch (err) {
          this.isSaving = false
          this.notificationError()
        }
        this.$v.$reset()
      }
    },
    updateIndex(index) {
      this.wizardIndex = index
    },
    checkRule() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()
        return true
      }
      return false
    },
    checkGroup() {
      this.$v.$touch()
      this.$refs.groupHours.$v.$touch()

      if (!this.$v.$invalid && !this.$refs.groupHours.$v.$invalid) {
        this.$refs.groupHours.convertData()
        this.group.weekdays = this.$refs.groupHours.weekdays
        this.group.hours = this.$refs.groupHours.modelData.hours

        this.$refs.groupHours.$v.$reset()
        this.$v.$reset()
        return true
      }
      return false
    },
    checkSubgroup() {
      return this.subgroups.every((sub, idx) => {
        this.$refs[`subgroupHours${idx}`][0].$v.$touch()
        if (!this.$refs[`subgroupHours${idx}`][0].$v.$invalid) {
          this.$refs[`subgroupHours${idx}`][0].convertData()
          sub.weekdays = this.$refs[`subgroupHours${idx}`][0].weekdays
          sub.hours = this.$refs[`subgroupHours${idx}`][0].modelData.hours
          sub.hours = { ...sub.hours, ...this.group.hours }

          this.$refs[`subgroupHours${idx}`][0].$v.$reset()
          return true
        }
        return false
      })
    },
    addSubgroup() {
      this.subgroups.push({ name: '', hours: [], weekdays: this.subgroupWeekday, default: '0' })
    },
  },
  validations() {
    return {
      rule: {
        required,
      },
      group: {
        name: {
          required: requiredIf(() => {
            return this.wizardIndex === 1
          }),
        },
        countries: {
          required: requiredIf(() => {
            return this.wizardIndex === 1
          }),
        },
      },
      subgroups: {
        $each: {
          name: {
            required: requiredIf(() => {
              return this.wizardIndex === 2
            }),
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.ohgroup-create {
  &__group {
    &__input {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__name,
      &__countries {
        width: calc(100% / 2 - (#{$gutter-mobile} / 2));
      }
    }
  }

  &__subgroup {
    margin-top: $gutter-tablet;
    border-top: 1px solid var(--text-color-alt);
    padding-top: $gutter-tablet/2;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-desktop;
      padding-top: $gutter-desktop/2;
    }

    &:nth-child(2) {
      margin-top: 0;
      border: none;
      padding-top: 0;
    }

    &__input {
      &__name {
        @media (min-width: $screen-sm) {
          width: 50%;
        }
      }
    }
  }

  &__addbutton {
    padding-bottom: $gutter-mobile;

    @media (min-width: $screen-md) {
      padding-bottom: $gutter-tablet;
    }

    @media (min-width: $screen-xl) {
      padding-bottom: $gutter-desktop;
    }
  }
}
</style>
